<script lang="ts">
	import { page } from '$app/stores'
	// import feature from '$lib/stores/feature';
	import { createEventDispatcher, onMount } from 'svelte'
	import { fade } from 'svelte/transition'
	import FullLogoIcon from '../icons/FullLogoIcon.svelte'

	// $: bannerFeatureEnabled = $feature['banner'];
	$: isBannerBlocked = $page.data.blockBanner

	export let isOpen = false
	const dispatch = createEventDispatcher()
	function closeMenu() {
		console.log('closeMenu')
		dispatch('close')
	}
</script>

{#if isOpen}
	<div class="relative z-50 w-full">
		<div transition:fade|local={{ duration: 100 }} class="absolute inset-0">
			<button
				class="fixed inset-0 bg-black overscroll-contain transform-gpu bg-opacity-20 backdrop-blur-sm backdrop-filter"
				on:click={closeMenu}
				on:touchstart={closeMenu}
			/>
		</div>

		<div
			class={`${
				!isBannerBlocked ? 'top-16 md:top-12' : 'top-0'
			} fixed inset-0 w-full h-auto pointer-events-none`}
		>
			<div
				class="pt-4 pb-6 bg-white w-full dark:bg-brand-gray-5 dark:text-white rounded-b-lg shadow-lg ring-1 ring-black ring-opacity-5 pointer-events-auto"
			>
				<div class="flex items-center justify-between px-5 w-full">
					<a href="/" class="flex focus:outline-none focus:ring-none">
						<FullLogoIcon height={'32'} width={'128'} />
						<span class="sr-only">Go to the airheart home page</span>
					</a>

					<button
						class="bg-brand-gray-5 border-brand-gray-4 dark:hover:bg-brand-gray-4 dark:hover:bg-opacity-20 hover:font-medium hover:border-transparent border dark:text-white text-black transition-all px-3 py-1 text-xs rounded-md"
						on:click={closeMenu}
						on:touchstart|stopPropagation={closeMenu}
					>
						<span class="sr-only">Close menu</span>
						<svg
							class="w-5 h-5"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width={2}
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
				<div class="px-2 mt-6 divide-y divide-brand-gray-4 dark:divide-brand-gray-2 w-full">
					<slot />
				</div>
			</div>
		</div>
	</div>
{/if}
